import gql from 'graphql-tag';
import get from 'lodash/get';
import React, {Fragment, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import User from './User';
import DrawerContext from '../../components/DrawerContext';
import ItemDrawer1 from '../../components/ItemDrawer1';

const USER_SMALL_FRAGMENT = gql`
   fragment userSmallInfo on User {
      id
      firstName
      lastName
      username
      isDeleted
   }
`;

export const USERS_QUERY = gql`
   query getUsers
   {
      users:user_All {
         ...userSmallInfo
      }
   }
   ${USER_SMALL_FRAGMENT}
`;

/**
 * The component that shows a list of users in the sidebar and the selected user.
 */
function UserView({ match}) {
   const {data, statusComponent} = useQueryFHG(USERS_QUERY, {fetchPolicy: 'cache-and-network'});

   const { isDrawerOpen } = useContext(DrawerContext);
   return (
      <Fragment>
         {statusComponent}
         <ItemDrawer1
            items={get(data, 'users')}
            prefixKey={'userDrawer'}
            uri={'/user'}
            onGetName={item => item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : item.username || 'N/A'}
            allowCreate
            open={isDrawerOpen}
            variant="persistent"
         />
         <User key={`userView${match.params.id}`}/>
      </Fragment>
   );
}

export default withRouter(UserView);
