import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grow from '@material-ui/core/Grow/Grow';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import useTheme from '@material-ui/core/styles/useTheme';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SearchIcon from '@material-ui/icons/Search';
import MoreVert from '@material-ui/icons/MoreVert';
import {Auth} from 'aws-amplify';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {parse, stringify} from 'query-string';
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Link, useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {
  APPBAR_HEIGHT,
  APPBAR_SMALL_HEIGHT,
  ASTM_ZIP_PATH,
  AUDIT_TYPE,
  CLIENTS_ALL_PATH,
  CLIENTS_PATH,
  CPSC_PDF_PATH,
  DASHBOARD_PATH,
  INCLUSIVE_TYPE,
  INSPECTION_FINALIZED_PATH,
  INSPECTION_NEW_PATH,
  INSPECTION_PATH,
  INSPECTION_PROPERTY_PATH,
  INSPECTION_TYPE,
  INVENTORY_PATH,
  LOGO_SMALL,
  LOGO_SMALL_DARK,
  NON_COMPLIANCE_REPORT_PATH,
  POWER_WASH_TYPE,
  PROPERTIES_ALL_PATH,
  PROPERTIES_PATH,
  QUESTION_ALL_PATH,
  QUESTIONS_PATH,
  REGULATION_LIST_PATH,
  USERS_ALL_PATH,
  USERS_PATH
} from '../Constants';
import AppearanceContext, {AUTO_MODE, DARK_MODE, LIGHT_MODE} from '../fhg/components/AppearanceContext';
import CsvExportButton from '../fhg/components/CsvExportButton';
import useQueryFHG from '../fhg/components/data/useQueryFHG';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import useEditData from '../fhg/components/edit/useEditData';
import Grid from '../fhg/components/Grid';
import Search from '../fhg/components/Search';
import FHGTypography from '../fhg/components/Typography';
import Typography from '../fhg/components/Typography';
import useWidthRule from '../fhg/hooks/useWidthRule';
import {convertImageToWrapper, fetchData} from '../fhg/utils/Utils';
import {showFile} from '../Utils/ServerUtil';
import DrawerContext from './DrawerContext';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {ClientImpersonationDropdown} from './ClientImpersonationDropdown';
import {useClientImpersonationContextState} from '../ClientImpersonationContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { PROPERTY_QUERY } from '../pages/property/Property';
import { darken } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      flex: '0 0 auto',
      height: theme.palette.type === 'dark' ? APPBAR_HEIGHT + 6 : APPBAR_HEIGHT,
      backgroundImage:
        theme.palette.type === 'dark' ? 'linear-gradient(#58c7d9,#1796a4,#007e80)' : undefined,
      borderBottomColor: theme.palette.type === 'dark' ? '#fbb040' : undefined,
      borderBottomStyle: theme.palette.type === 'dark' ? 'solid' : undefined,
      borderBottomWidth: theme.palette.type === 'dark' ? 3 : undefined,
      [theme.breakpoints.down('sm')]: {
        height: APPBAR_SMALL_HEIGHT,
      },
      position: 'relative',
      backgroundColor: theme.palette.background.paper
    },
    activePathStyle: {
      background: darken(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.contrastText
    },
    toolBarStyle: {
      height: APPBAR_HEIGHT,
      [theme.breakpoints.down('sm')]: {
        height: APPBAR_SMALL_HEIGHT,
        minHeight: APPBAR_SMALL_HEIGHT,
      },
    },
    titleStyle: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        marginLeft: theme.spacing(2),
      },
    },
    version: {
      fontSize: 12,
      marginTop: -18,
      paddingLeft: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        marginTop: -8,
        paddingLeft: 0,
      },
    },
    imageStyle: {
      marginRight: theme.spacing(2),
      height: 48,
      // width: 240,
      [theme.breakpoints.down('sm')]: {
        height: 30,
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        height: 28,
        maxWidth: '100%',
      },
    },
    logoutStyle: {
      whiteSpace: 'nowrap',
      color: 'white',
      backgroundColor: 'transparent',
      marginLeft: theme.spacing(2),
    },
    selectStyle: {
      '& svg': {
        fill: theme.palette.type === 'dark' ? 'white' : '#1796a4',
      },
    },
    dividerStyle: {
      // backgroundColor: theme.palette.secondary.main,
      height: '50%',

      margin: `auto ${theme.spacing(2)}px`,
      [theme.breakpoints.down('xs')]: {
        margin: `auto ${theme.spacing(0.5)}px`,
      },
    },
    radioStyle: {
      padding: 4,
    },
    menuButtonStyle: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    buttonStyle: {
      color: theme.palette.type === 'dark' ? 'white' : undefined,
    },
    progressStyle: {
      position: 'absolute',
      top: 80,
      left: '50%',
    },
  }),
  { name: 'SearchAppBarStyles' }
);

export const FILTER_QUERY = gql`
  query FilterQuery {
    standard: regulationStandard_All {
      id
      isDeleted
      name
    }
  }
`;

export const CLIENT_QUERY = gql`
  query getMyClient {
    client: getMyClient {
      logoImageData {
        imageS3
      }
    }
  }
`;

const useFilterStyles = makeStyles(theme => ({
  radioStyle: {
    padding: 4,
  },
  leftSpace: {
    marginLeft: theme.spacing(1),
  },
  midLeftSpace: {
    marginLeft: theme.spacing(1.5),
  },
}));

function FilterMasterQuestions(props) {
  const classes = useFilterStyles();
  return (
    <>
      <IconButton
        className={'no-print'}
        aria-owns={'menu-appbar'}
        aria-haspopup='true'
        onClick={props.onClick}
        color='inherit'
      >
        <FilterIcon color={'primary'} />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.onClose}
      >
        <FormControl
          component='fieldset'
          fullWidth
          className={classes.leftSpace}
        >
          <FormLabel component='legend'>Show</FormLabel>
          <RadioGroup
            aria-label='standard'
            // row
            name='type'
            value={props.value}
            onChange={props.onChange}
          >
            <MenuItem dense>
              <FormControlLabel
                value={'0'}
                control={<Radio className={classes.radioStyle} />}
                label='Audit, Power Wash and Inspection'
              />
            </MenuItem>
            <MenuItem dense>
              <FormControlLabel
                value={INCLUSIVE_TYPE.toString()}
                control={<Radio className={classes.radioStyle} />}
                label={'Inclusive Inspection Only'}
              />
            </MenuItem>
            <MenuItem dense>
              <FormControlLabel
                value={INSPECTION_TYPE.toString()}
                control={<Radio className={classes.radioStyle} />}
                label={'Inspection Only'}
              />
            </MenuItem>
            <MenuItem dense>
              <FormControlLabel
                value={AUDIT_TYPE.toString()}
                control={<Radio className={classes.radioStyle} />}
                label={'Audit Only'}
              />
            </MenuItem>
            <MenuItem dense>
              <FormControlLabel
                value={POWER_WASH_TYPE.toString()}
                control={<Radio className={classes.radioStyle} />}
                label={'Power Wash Only'}
              />
            </MenuItem>
          </RadioGroup>
        </FormControl>
        <FormControl
          component='fieldset'
          fullWidth
          className={classes.leftSpace}
        >
          <FormLabel component='legend'>Standard</FormLabel>
          <RadioGroup
            aria-label='standard'
            // row
            name='standard'
            value={props.value1}
            onChange={props.onChange}
          >
               {get(props.object, 'standard', []).map(standard => (
              <MenuItem key={`standardsMenuItem${standard.name}`} dense>
                <FormControlLabel
                  value={standard.name}
                  control={<Radio className={classes.radioStyle} />}
                  label={standard.name}
                />
              </MenuItem>
            ))}
            <MenuItem dense>
              <FormControlLabel
                value='All'
                control={<Radio className={classes.radioStyle} />}
                label='All'
              />
            </MenuItem>
          </RadioGroup>
        </FormControl>
        <MenuItem onClick={props.onClick1}>
          <Typography
            color='inherit'
            variant='body1'
            className={classes.midLeftSpace}
            id={'questionFilters.clear.item'}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

FilterMasterQuestions.propTypes = {
  onClick: PropTypes.func,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  value1: PropTypes.any,
  object: PropTypes.any,
  onClick1: PropTypes.func,
};

SearchAppBar.propTypes = {
  onShowSignup: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  showSearch: PropTypes.bool,
};

/**
 * The AppBar with search and export to CSV capabilities.
 */
export default function SearchAppBar({
  onDownloadToCsv,
  showCsv,
  showSearch = false,
  isSuperAdmin,
  isAdmin,
  isPlanner,
  isInspector,
  isPropertyOwner,
  showDrawer = true,
  userName,
  onStateChange,
  onShowSignup,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { isDarkMode, setAppearanceSetting, appearanceSetting } =
    useContext(AppearanceContext);
  const { isDrawerOpen, onDrawerOpen, onDrawerClose } = useContext(DrawerContext);
  const anchorRef = useRef();
  const propertyRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { mode } = useParams();
  const isQuestionMatch = useRouteMatch({path: QUESTIONS_PATH, strict: true, sensitive: true});

  const isMediumUp = useWidthRule('up', 'md');
  const isXSmallDown = useWidthRule('down', 'xs');
  const isSmallUp = useWidthRule('up', 'sm');

  const searchParams = parse(location.search, {parseNumbers: true, parseBooleans: true});
  const [isSearchShowing, setIsSearchShowing] = useState(
    isMediumUp && showSearch
  );
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const [openProperties, setOpenProperties] = useState(false);
  const [navMenuElement, setNavMenuElement] = useState(null);

  const { data } = useQueryFHG(FILTER_QUERY, {skip: !isSearchShowing});
  const { data: clientData } = useQueryFHG(CLIENT_QUERY);

  const [editValues, handleChange, { setDefaultValues, resetValues }] =
    useEditData({
      type: searchParams.type,
      standard: searchParams.standard === undefined ? 'All' : searchParams.standard,
    });

  const [isDownloading, setIsDownloading] = useState(false);
  const [errorData, setErrorData] = useState({});
  const logoImageUrl = clientData ? (clientData.client?.logoImageData?.imageS3 ?
      convertImageToWrapper(clientData.client.logoImageData.imageS3) : isDarkMode ? LOGO_SMALL_DARK : LOGO_SMALL) : '';

  useEffect(() => {
      const searchParams = parse(location.search, {parseNumbers: true, parseBooleans: true});

    setDefaultValues({
      type: searchParams.type,
      standard: searchParams.standard === undefined ? 'All' : searchParams.standard
    });
  }, [location.search, setDefaultValues]);

  useEffect(() => {
    setIsSearchShowing(isMediumUp && showSearch);
  }, [isMediumUp, showSearch]);

  const handleShowSearch = () => {
    setIsSearchShowing(showSearch);
  };

  const handleHideSearch = () => {
    setIsSearchShowing(isMediumUp);
  };

  const handleLogOut = () => {
    Auth.signOut()
      .then(() => {
        if (onStateChange) {
          onStateChange('signedOut');
        }
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

   const handleLogoutChange = event => {
    if (event.target.value === 'logout') {
      handleLogOut();
    } else if (event.target.value === 'createAccount') {
      handleCreateAccount();
    }
  };

  const handleCreateAccount = () => {
    setIsAccountMenuOpen(false);
    onShowSignup && onShowSignup();
  };

   const handleFilterMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = () => {
    setIsAccountMenuOpen(true);
  };

  const handleClose = () => {
    setIsAccountMenuOpen(false);
  };

  useEffect(() => {
      const existingSearch = parse(location.search, {parseBooleans: true, parseNumbers: true});
    const search = stringify({
      ...existingSearch,
      type: editValues.type,
      standard: editValues.standard === 'All' ? undefined : editValues.standard
    });

    if (search !== location.search) {
      history.push({ pathname: location.pathname, search });
    }

   }, [editValues]);

  const handleClearFilters = () => {
    setAnchorEl(null);
    resetValues();
  };

  /**
   * Toggle the inspect menu button.
   */
  const handleToggle = () => {
    setOpen(true);
  };

  /**
   * Close inspection menu.
   */
  const handleInspectClose = () => {
    setOpen(false);
  };

  /**
   * Toggle the properties menu button.
   */
  const handleToggleProperties = () => {
    setOpenProperties(prev => !prev);
  };

  /**
   * Toggles the nav menu.
   */
  const toggleNavMenu = (event) => {
    setNavMenuElement(navMenuElement ? null : event.target.closest('button'));
  };

  /**
   * Closes the nav menu.
   */
  const closeNavMenu = (event) => {
    setNavMenuElement(null);
  };


  /**
   * Get the ASTM document for inspectors.
   */
  const handleASTM = () => {
    handleClose();
    setIsDownloading(true);
    return fetchData(ASTM_ZIP_PATH, 'GET', undefined, 'application/blob', true)
      .then((result) => {
        showFile('ASTM', result, 'zip');
        setIsDownloading(false);
         }).catch(error => {
        console.log('Error fetching ASTM document', error);
        setErrorData({ error, name: 'ASTM ZIP', message: error.message });
        setIsDownloading(false);
      });
  };

  /**
   * Get the CPSC document for inspectors.
   */
  const handleCPSC = () => {
    handleClose();
    setIsDownloading(true);
    return fetchData(CPSC_PDF_PATH, 'GET', undefined, 'application/blob', true)
      .then((result) => {
        showFile('CPSC', result);
        setIsDownloading(false);
         }).catch(error => {
        console.log('Error fetching CPSC document', error);
        setErrorData({ error, name: 'CPSC PDF', message: error.message });
        setIsDownloading(false);
      });
  };

  const handleErrorClose = () => {
    setErrorData({});
  };

  const handleAppearanceSettingChange = (event) => {
    setAppearanceSetting(event.target.value);
  };

  // const {classes, onDownloadToCsv, showCsv, showSearch, width, isAdmin, isPlanner, isInspector, match, showDrawer =
  // true, userName} = this.props; const {anchorEl, isAccountMenuOpen, isSearchShowing, open} = this.state; const
  // {onDrawerOpen, isDrawerOpen} = this.context;

  const isAccountant = isAdmin;

  const menuOpen = Boolean(anchorEl);
  let titleKey;

  switch (location.pathname) {
    case INVENTORY_PATH:
      titleKey = 'inspect.inventory.title';
      break;
    case INSPECTION_PATH:
      titleKey = 'inspect.properties.title';
      break;
    case INSPECTION_PROPERTY_PATH:
    case PROPERTIES_PATH:
      titleKey = 'inspect.property.title';
      break;
    case INSPECTION_NEW_PATH:
      titleKey =
        mode === 'inspect' ? 'inspect.inspection.title' : 'inspect.audit.title';
      break;
    case INSPECTION_FINALIZED_PATH:
      titleKey = 'final.title';
      break;
    case NON_COMPLIANCE_REPORT_PATH:
      titleKey = 'nonComplianceReport.title';
      break;
    case QUESTIONS_PATH:
    case QUESTION_ALL_PATH:
      titleKey = 'masterQuestions.title';
      break;
    case USERS_PATH:
    case USERS_ALL_PATH:
      titleKey = 'user.title';
      break;
    case CLIENTS_PATH:
    case CLIENTS_ALL_PATH:
      titleKey = 'client.title';
      break;
    default:
      titleKey = undefined;
      break;
  }

  const isPropertyPath = location.pathname.includes('/property');

  const showTitle =
    location.pathname.includes('/show-assets') || location.pathname.includes('/map');

  const router = useRouteMatch()

   const id = Number(get(router, 'params.id', 0));

   const { data: dataProperty } = useQueryFHG(PROPERTY_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  const property = get(dataProperty, 'property');

  // noinspection HtmlUnknownTarget
  return (
    <AppBar position='relative' color={'inherit'} className={classes.appBar}>
      <DataLoadingAndErrors
        isLoading={isDownloading}
        error={errorData.error}
        errorId={'inspect.download.error'}
        values={{ ...errorData }}
        onClose={handleErrorClose}
        classes={{ progressStyle: classes.progressStyle }}
      />
      <Toolbar disableGutters={isXSmallDown} className={classes.toolBarStyle}>
        {
          showDrawer &&
          <IconButton className={classes.menuButtonStyle} color='inherit' aria-label='Open drawer'
              onClick={isDrawerOpen ? onDrawerClose : onDrawerOpen}
          >
            {isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        }
       
        {logoImageUrl && (
            <img alt="" className={classes.imageStyle} src={logoImageUrl}/>
        )}

        {isSuperAdmin ? <ClientImpersonationDropdown /> : null}

        <div className={classes.grow} />
        {showTitle && <Typography variant={'h6'} color={'primary'}>{property?.name}</Typography>}
        {titleKey && <Typography variant={'h6'} color={'primary'} id={titleKey} />}
        <div className={classes.grow} />
            {showCsv && (
               <CsvExportButton onClick={onDownloadToCsv}/>
            )}
        {isSearchShowing && (
          <Grow in={isSearchShowing}>
            <Fragment>
              <Search classes={classes} onClose={handleHideSearch} />
               {(isQuestionMatch && isQuestionMatch.isExact) && (
                <FilterMasterQuestions
                  onClick={handleFilterMenu}
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleFilterClose}
                  value={editValues.type}
                  onChange={handleChange}
                  value1={editValues.standard}
                  object={data}
                  onClick1={handleClearFilters}
                />
              )}
              <Hidden smDown>
                <Divider
                  className={classes.dividerStyle}
                  orientation={'vertical'}
                />
              </Hidden>
            </Fragment>
          </Grow>
        )}
        <Hidden mdUp>
          <IconButton
            color='primary'
            aria-label='Open search'
            style={{display: isSearchShowing || !showSearch ? 'none' : undefined}}
            onClick={handleShowSearch}
          >
            <SearchIcon />
          </IconButton>
        </Hidden>
            <div style={{display: isSmallUp || !isSearchShowing ? undefined : 'none'}}>
               <Hidden smDown>
                  {isPlanner && (
                     <Button component={Link} to={DASHBOARD_PATH}
                             color='primary' className={`${classes.buttonStyle} ${location.pathname === DASHBOARD_PATH ? classes.activePathStyle : ''}`}>
                       <FHGTypography id='dashboard.nav'/>
                     </Button>
                  )}
                  {isInspector && (
                     <Button component={Link} to={REGULATION_LIST_PATH}
                            //  style={{display: location.pathname === REGULATION_LIST_PATH ? 'none' : undefined}}
                             color='primary' className={`${classes.buttonStyle} ${location.pathname === REGULATION_LIST_PATH ? classes.activePathStyle : ''}`}>
                       <FHGTypography id='searchable.regulation.nav'/>
                     </Button>
                  )}
                  {isAccountant ? (
                     <Fragment>
                        <Button component={Link} to='/inspect' ref={anchorRef} className={`${classes.buttonStyle} ${location.pathname === INSPECTION_PATH ? classes.activePathStyle : ''}`}
                                color='primary' style={{borderRight: 'none', paddingRight: 0}}>
                           <Typography id='inspect.nav'/>
                        </Button>
                        <Button
                           color='primary'
                           className={classes.buttonStyle}
                           size='small'
                           aria-controls={open ? 'split-button-menu' : undefined}
                           aria-expanded={open ? 'true' : undefined}
                           aria-label='select merge strategy'
                           aria-haspopup='menu'
                           onClick={handleToggle}
                           style={{paddingLeft: 0, paddingRight: 0, minWidth: 20}}
                        >
                           <ArrowDropDownIcon/>
                        </Button>
                        <Menu
                           id='simple-menu'
                           anchorEl={anchorRef.current}
                           keepMounted
                           open={open}
                           onClose={handleInspectClose}
                        >
                           <MenuItem component={Link} to={INSPECTION_FINALIZED_PATH} onClick={handleInspectClose}>
                              <Typography id='inspect.finalized.label' variant='menuItem'/>
                           </MenuItem>
                          <MenuItem component={Link} to={NON_COMPLIANCE_REPORT_PATH} onClick={handleInspectClose}>
                            <Typography id='inspect.nonComplianceReport.label' variant='menuItem'/>
                          </MenuItem>
                        </Menu>
                     </Fragment>
                  ) : (isInspector || isPropertyOwner) && (
                     <Button component={Link} to='/inspect'
                            //  style={{display: location.pathname === INSPECTION_PATH ? 'none' : undefined}}
                             color='primary' className={`${classes.buttonStyle} ${location.pathname === INSPECTION_PATH ? classes.activePathStyle : ''}`}>
                        <Typography id='inspect.nav'/>
                     </Button>
                  )}
                  {isPlanner && <>
                     <Button component={Link} to='/planning'
                            //  style={{display: location.pathname === QUESTIONS_PATH ? 'none' : undefined}}
                             color='primary' className={`${classes.buttonStyle} ${location.pathname.includes(QUESTION_ALL_PATH) ? classes.activePathStyle : ''}`}>
                        <FHGTypography id='masterQuestions.nav'/>
                     </Button>
                     <Fragment>
                        <Button color='primary' component={Link} to='/property' className={`${classes.buttonStyle} ${location.pathname.includes(PROPERTIES_ALL_PATH) ? classes.activePathStyle : ''}`} ref={propertyRef}
                                // style={{display: location.pathname === PROPERTIES_PATH ? 'none' : undefined}}
                                >
                          <Typography id='property.nav'/>
                        </Button>
                        <Button
                          color='primary'
                          className={classes.buttonStyle}
                          size='small'
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label='select merge strategy'
                          aria-haspopup='menu'
                          onClick={handleToggleProperties}
                          style={{paddingLeft: 0, paddingRight: 0, minWidth: 20}}
                        >
                          <ArrowDropDownIcon/>
                        </Button>
                        <Menu
                           id='simple-menu'
                           anchorEl={propertyRef.current}
                           keepMounted
                           open={openProperties}
                           onClose={handleToggleProperties}
                        >
                           <MenuItem component={Link} to={`${PROPERTIES_ALL_PATH}?isCreateProperty=true`} onClick={handleToggleProperties}>
                              <Typography id='property.new.button' variant='menuItem'/>
                           </MenuItem>
                        </Menu>
                     </Fragment>
                  </>}
                  {isAdmin && (
                     <Button color='primary' component={Link} to='/user' className={`${classes.buttonStyle} ${location.pathname.includes(USERS_ALL_PATH) ? classes.activePathStyle : ''}`}
                            //  style={{display: location.pathname === USERS_PATH ? 'none' : undefined}}
                             >
                        <Typography id='user.nav'/>
                     </Button>
                  )}
                              {isSuperAdmin && (
                    <Button
                      color='primary'
                      component={Link}
                      to='/client'
                      className={`${classes.buttonStyle} ${location.pathname.includes(CLIENTS_ALL_PATH) ? classes.activePathStyle : ''}`}
                      // style={{
                      //   display:
                      //     location.pathname === CLIENTS_PATH ? 'none' : undefined,
                      // }}
                    >
                      <Typography id='client.nav' />
                    </Button>
                  )}
               </Hidden>
            </div>
            <Hidden mdUp>
              <Button
                  color='primary'
                  className={classes.buttonStyle}
                  size='small'
                  aria-controls={toggleNavMenu ? 'nav-menu' : undefined}
                  aria-expanded={toggleNavMenu ? 'true' : undefined}
                  aria-label='select merge strategy'
                  aria-haspopup='menu'
                  onClick={toggleNavMenu}
                  style={{ minWidth: 'auto'}}
              >
                <MoreVert/>
              </Button>
              <Menu
                  id='nav-menu'
                  anchorEl={navMenuElement}
                  keepMounted
                  open={Boolean(navMenuElement)}
                  onClose={closeNavMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
              >
                {isPlanner && (
                    <MenuItem component={Link} to={DASHBOARD_PATH} onClick={closeNavMenu}>
                      <Typography id='dashboard.nav' variant='menuItem'/>
                    </MenuItem>
                )}
                {isInspector && (
                    <MenuItem component={Link} to={REGULATION_LIST_PATH} onClick={closeNavMenu}>
                      <Typography id='searchable.regulation.nav' variant='menuItem'/>
                    </MenuItem>
                )}
                {(isAccountant || isInspector || isPropertyOwner) && (
                    <MenuItem component={Link} to={INSPECTION_PATH} onClick={closeNavMenu}>
                      <Typography id='inspect.nav' variant='menuItem'/>
                    </MenuItem>
                )}
                {isAccountant && (
                    <>
                      <MenuItem component={Link} to={INSPECTION_FINALIZED_PATH} onClick={closeNavMenu}>
                        <Typography id="inspect.finalized.label" variant="menuItem"/>
                      </MenuItem>
                      <MenuItem component={Link} to={NON_COMPLIANCE_REPORT_PATH} onClick={closeNavMenu}>
                        <Typography id="inspect.nonComplianceReport.label" variant="menuItem"/>
                      </MenuItem>
                    </>
                )}
                {isPlanner && (
                    <>
                      <MenuItem component={Link} to={QUESTION_ALL_PATH} onClick={closeNavMenu}>
                        <Typography id='masterQuestions.nav' variant='menuItem'/>
                      </MenuItem>
                      <MenuItem component={Link} to={PROPERTIES_ALL_PATH} onClick={closeNavMenu}>
                        <Typography id='property.nav' variant='menuItem'/>
                      </MenuItem>
                    </>
                )}
                {isAdmin && (
                    <MenuItem component={Link} to={USERS_ALL_PATH} onClick={closeNavMenu}>
                      <Typography id='user.nav' variant='menuItem'/>
                    </MenuItem>
                )}
                {isSuperAdmin && (
                    <MenuItem component={Link} to={CLIENTS_ALL_PATH} onClick={closeNavMenu}>
                      <Typography id='client.nav' variant='menuItem'/>
                    </MenuItem>
                )}
              </Menu>
            </Hidden>
            <Grid container alignItems={'center'} fullWidth={false} direction={'column'}>
               {/*<IconButton color='primary' aria-label='Open search' onClick={this.handleMenu} style={{padding: 0}}>*/}
               {/*   <AccountCircle fontSize='large' color={'primary'}/>*/}
               {/*</IconButton>*/}
               <FormControl>
                  {/*<InputLabel htmlFor='demo-controlled-open-select'>John</InputLabel>*/}
                  <Select
                     open={isAccountMenuOpen}
                     className={classes.selectStyle}
                     onClose={handleClose}
                     onOpen={handleMenu}

                     renderValue={() => (
                        <Grid container wrap={'nowrap'} direction={'row'} alignItems={'center'}>
                           <AccountCircle fontSize='large' color={'primary'}/>
                           <Hidden mdDown>
                              <Typography variant={'button'} color={'primary'}>
                                 {userName}
                              </Typography>
                           </Hidden>
                        </Grid>
                     )}
                     disableUnderline={true}
                     displayEmpty={true}
                     value={''}
                     onChange={handleLogoutChange}
            >
              <FormControl
                component='fieldset'
                style={{paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1)}}
              >
                <FormLabel component='legend'>Appearance</FormLabel>
                <RadioGroup
                  aria-label='appearance mode'
                  name='mode'
                  value={appearanceSetting}
                  onChange={handleAppearanceSettingChange}
                >
                  <FormControlLabel
                    value={DARK_MODE}
                    control={<Radio />}
                    label='Dark'
                  />
                  <FormControlLabel
                    value={LIGHT_MODE}
                    control={<Radio />}
                    label='Light'
                  />
                  <FormControlLabel
                    value={AUTO_MODE}
                    control={<Radio />}
                    label='Auto'
                  />
                </RadioGroup>
              </FormControl>

              <MenuItem value='logout' component={Link} onClick={handleLogOut}>
                <Typography id='path.logout' variant='menuItem'>
                  Logout
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
