import Badge from '@material-ui/core/Badge/Badge';
import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import { find, pullAllBy, filter, delay, isFunction, defer, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import { getEquipmentForQuestionType } from '../../Utils/dataUtil';
import { REPORT_QUERY } from './AreaBuilder';
import { TEMPLATE_QUERY } from './InspectionBuilder';
import ItemMenu from './ItemMenu';
import QuestionBuilder from './QuestionBuilder';

const useStyles = makeStyles((theme) => ({
  largeLeftSpace: {
    // marginLeft: theme.spacing(2),
  },
  leftSpace: {
    marginLeft: theme.spacing(1),
  },
  buttonPanelStyle: {
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
  },
}));

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.type === 'dark' ? '#B5B5B5' : '#fafafa',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiExpansionPanelDetails);

const EQUIPMENT_FRAGMENT = gql`
  fragment equipmentInfo on Equipment {
    id
    name
    equipmentMasterId
    isDeleted
  }
`;

const EQUIPMENT_CREATE = gql`
  mutation EquipmentCreate(
    $name: String!
    $equipmentMasterId: Int
    $areaId: Int!
    $description: String
  ) {
    equipment: equipment_Create(
      equipment: {
        name: $name
        equipmentMasterId: $equipmentMasterId
        areaId: $areaId
        description: $description
      }
    ) {
      ...equipmentInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const EQUIPMENT_UPDATE = gql`
  mutation EquipmentUpdate($id: Int!, $name: String, $areaId: Int, $description: String) {
    equipment: equipment_Update(
      equipmentId: $id
      equipment: { name: $name, areaId: $areaId, description: $description }
    ) {
      ...equipmentInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const EQUIPMENT_DELETE = gql`
  mutation equipmentDelete($id: Int!) {
    equipment_Delete(equipmentId: $id)
  }
`;

/**
 * Component to display equipment expansion panel.
 *
 * Reviewed: 9/24/19
 */
function EquipmentBuilder({
  property,
  area,
  masterAreas,
  propertyEquipment = [],
  questionTypeId,
  reportId,
}) {
  const [expanded, setExpanded] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [equipmentList, setEquipmentList] = useState([]);
  const [masterEquipment, setMasterEquipment] = useState([]);
  const [scrollToEquipment, setScrollToEquipment] = useState();
  const [equipmentCreate, createEquipmentStatusComponent] = useMutationFHG(EQUIPMENT_CREATE);
  const [equipmentDelete, deleteEquipmentStatusComponent] = useMutationFHG(EQUIPMENT_DELETE);
  const classes = useStyles();

  useEffect(() => {
    let masterEquipment = [];

    if (!!masterAreas) {
      const masterArea = find(masterAreas, { name: area.name });

      if (!!masterArea) {
        masterEquipment = masterArea.equipment;

        if (questionTypeId && masterEquipment?.length > 0) {
          masterEquipment = getEquipmentForQuestionType(
            masterEquipment,
            questionTypeId,
            'questions',
            'questionTypeId',
          );
        }
      }
    }
    setMasterEquipment(masterEquipment);
  }, [masterAreas, area.name, questionTypeId]);

  useEffect(() => {
    if (propertyEquipment.length > 0 || equipmentList.length > 0) {
      let newEquipment =
        propertyEquipment.length > 0 ? filter(propertyEquipment, { isDeleted: false }) : [];

      if (questionTypeId && masterEquipment?.length > 0) {
        newEquipment = getEquipmentForQuestionType(
          newEquipment,
          questionTypeId,
          'questionAliases',
          'question.questionTypeId',
          masterEquipment,
        );
      }
      setEquipmentList(newEquipment);
    }
    // eslint-disable-next-line
  }, [propertyEquipment, masterEquipment]);

  useEffect(() => {
    if (scrollToEquipment) {
      delay(() => {
        const elements = document.getElementsByName(`Equipment ${scrollToEquipment.id}`);
        if (elements && elements[0] && isFunction(elements[0].scrollIntoViewIfNeeded)) {
          elements[0].scrollIntoViewIfNeeded();
        }
      }, 750);
    }
  }, [scrollToEquipment]);

  /**
   * Handle expanding the panel.
   * @param equipment The question ID of the panel or -1 for the new question panel.
   * @return {Function} The callback for the expanding.
   */
  const handleExpand = (equipment) => (event, newExpanded) => {
    setExpanded(newExpanded ? equipment.id : false);
  };

  const getRefetchQueries = () => {
    const queries = [{ query: TEMPLATE_QUERY, variables: { propertyId: property.id } }];

    if (!!reportId) {
      queries.push({ query: REPORT_QUERY, variables: { reportId } });
    }

    return queries;
  };

  const handleDelete = (equipment) => (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    equipmentDelete({ variables: { id: equipment.id }, refetchQueries: getRefetchQueries });

    setEquipmentList(pullAllBy(equipmentList, [{ id: equipment.id }], 'id'));
  };

  const handleAddEquipment = async (addedEquipment = [], useAll) => {
    const newEquipment = [];

    for (const equipment of addedEquipment) {
      const result = await equipmentCreate({
        variables: { name: equipment.name, equipmentMasterId: equipment.id, areaId: area.id },
      });
      newEquipment.push(result.data.equipment);
    }
    setEquipmentList([...equipmentList, ...newEquipment]);
    if (newEquipment.length > 0) {
      defer(() => {
        setExpanded(get(newEquipment, `[${newEquipment.length - 1}].id`));
        setScrollToEquipment(newEquipment[newEquipment.length - 1]);
      });
    }
  };

  const handleOpenAddEquipment = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAddEquipment = (event) => {
    setAnchorEl(undefined);
  };

  let counts = {};

  return (
    <Grid
      item
      container
      direction={'column'}
      fullWidth
      name={'test width'}
      spacing={2}
      style={{ borderBottom: '1px solid lightGrey', width: 'calc(100% + 16px)' }}
    >
      {createEquipmentStatusComponent || deleteEquipmentStatusComponent}
      <Grid item>
        {equipmentList.map((equipment, index) => {
          counts[equipment.name] =
            counts[equipment.name] === undefined ? 1 : counts[equipment.name] + 1;
          return (
            <ExpansionPanel
              key={`EquipmentProperty ${equipment.id}`}
              name={`Equipment ${equipment.id}`}
              expanded={expanded === equipment.id}
              onChange={handleExpand(equipment)}
            >
              <ExpansionPanelSummary
                key={`EquipmentPropertySummary ${equipment.id}`}
                expandIcon={
                  <ExpandMoreIcon color={expanded === equipment.id ? 'secondary' : 'primary'} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid
                  container
                  direction={'row'}
                  wrap={'nowrap'}
                  justify={'space-between'}
                  overflow={'visible'}
                >
                  <Grid item overflow={'visible'}>
                    <Badge
                      color="secondary"
                      badgeContent={equipment.__approveCount}
                      className={classes.margin}
                      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                      <Typography className={classes.largeLeftSpace}>
                        {`${equipment.name} - ${counts[equipment.name]}`}
                      </Typography>
                    </Badge>
                  </Grid>
                  {!reportId && (
                    <Grid item>
                      <Button
                        onClick={handleDelete(equipment)}
                        className={classes.leftSpace}
                        color={'default'}
                        variant="contained"
                      >
                        <Typography
                          color="inherit"
                          id={'propertyQuestions.delete.button'}
                          values={equipment}
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {expanded === equipment.id && (
                  <QuestionBuilder
                    key={`QuestionPanelDetailsProperty ${equipment.id}`}
                    property={property}
                    masterEquipment={masterEquipment}
                    area={area}
                    equipment={equipment}
                    propertyQuestionAliases={equipment.questionAliases}
                    questionTypeId={questionTypeId}
                    reportId={reportId}
                  />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Grid>
      <Grid item className={classes.buttonPanelStyle}>
        <Button
          onClick={handleOpenAddEquipment}
          color={'primary'}
          variant="contained"
          disabled={masterEquipment && masterEquipment.length <= 0}
        >
          <Typography color="inherit">Add Equipment</Typography>
        </Button>
        <ItemMenu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseAddEquipment}
          items={masterEquipment}
          onAddItems={handleAddEquipment}
          itemName={'Equipment'}
          allName={'Equipment'}
        />
      </Grid>
    </Grid>
  );
}

EquipmentBuilder.propTypes = {
  area: PropTypes.object, // The Area of the Equipment.
  masterEquipment: PropTypes.array, // The master equipment for the Area.
  equipment: PropTypes.array, // All the equipment for the Area.
  expandEquipment: PropTypes.object, // Optional expanded equipment panel.
  actionQuestionKey: PropTypes.string, // Localization key for the name of the generic Question action.
};

EquipmentBuilder.defaultProps = {
  equipment: [],
};

export default EquipmentBuilder;
