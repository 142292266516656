import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs';
import gql from 'graphql-tag';
import * as PropTypes from 'prop-types';
import { useRef } from 'react';
import React, { useState, useEffect } from 'react';
import { AUDIT_TYPE, POWER_WASH_TYPE, INSPECTION_TYPE, INCLUSIVE_TYPE } from '../../Constants';
import {
  FACILITY_LOCATION_GEOCOD_PATH,
  FACILITY_LOCATION_PATH,
  ZOOM_DEFAULT,
} from '../../Constants';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import DisplayError from '../../fhg/components/DisplayError';
import Prompt from '../../fhg/components/edit/Prompt';
import Grid from '../../fhg/components/Grid';
import MapFHG from '../../fhg/components/map/MapFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import Typography from '../../fhg/components/Typography';
import { uploadPhotoS3 } from '../../fhg/utils/SubmitUtils';
import { fetchData } from '../../fhg/utils/Utils';
import { UPDATE_PROPERTY } from '../property/PropertyInfoEdit';
import AreaBuilder from './AreaBuilder';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import useFullScreen from '../../fhg/hooks/useFullScreen';

const INSPECT_TAB = 'inspect';
const AUDIT_TAB = 'audit';
const POWER_WASH_TAB = 'power wash';
const INCLUSIVE_INSPECTION_TAB = 'inclusive';
const MAP_TAB = 'map';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    height: '100%',
    minHeight: 300,
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  spinnerMargin: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.secondary.main,
  },
  tab: {
    borderRight: '1px solid',
    borderRightColor: 'gray',
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
      color: theme.palette.primary.main,
      '& .MuiTypography-root': {
        fontSize: '1.5rem',
      },
    },
    height: '50px',
  },
  indicatorStyle: {
    display: 'none',
  },
  tabNoBorder: {
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
      color: theme.palette.primary.main,
      '& .MuiTypography-root': {
        fontSize: '1.5rem',
      },
    },
    height: '50px',
  },
}));

export const AREA_FRAGMENT = gql`
  fragment areaInfoForBuilder on Area {
    id
    isDeleted
    name
    description
    equipment {
      id
      isDeleted
      name
      equipmentMasterId
      description
      questionAliases {
        id
        isDeleted
        markedForAdd
        markedForRemove
        question {
          id
          isDeleted
          text
          questionTypeId
        }
      }
      #         questions {
      #            id
      #            text
      #            masterEquipmentId
      #            questionTypeId
      #            isDeleted
      #         }
    }
  }
`;

export const TEMPLATE_QUERY = gql`
  query getTemplateForInspection($propertyId: Int) {
    template: template_AllWhere(templateSearch: { propertyId: [$propertyId], isDeleted: [false] }) {
      id
      isDeleted
      propertyId
      areas {
        ...areaInfoForBuilder
      }
    }
  }
  ${AREA_FRAGMENT}
`;

//TODO change questionsMarkedForAdd to correct questionIdList.
const TEMPLATE_CREATE = gql`
  mutation TemplateCreate($propertyId: Int!) {
    template: template_Create(
      template: { propertyId: $propertyId, statusId: 1, name: "n/a", version: 1 }
    ) {
      id
      isDeleted
      propertyId
    }
  }
`;

/**
 * Component to build the inspection for a property.
 *
 * Reviewed: 10/29/19
 */
function InspectionBuilder({ property, questionTypeId, reportId }) {
  const classes = useStyles();
  const [template, setTemplate] = useState(null);
  const [error, setError] = useState();
  const [errorId, setErrorId] = useState();
  const [errorValues, setErrorValues] = useState();
  const mapRef = useRef();
  const theme = useTheme();
  const isFullScreen = useFullScreen();

  const { loading, data, statusComponent } = useQueryFHG(TEMPLATE_QUERY, {
    variables: { propertyId: property.id },
    fetchPolicy: 'cache-and-network',
  });
  const [templateCreate, templateCreateStatusComponent] = useMutationFHG(TEMPLATE_CREATE);
  const [propertyUpdate, propertyUpdateStatusComponent] = useMutationFHG(UPDATE_PROPERTY);

  const [selectedTab, setSelectedTab] = useState(INSPECT_TAB);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 36.231265, lng: -115.110922 });
  const [defaultZoom, setDefaultZoom] = useState(ZOOM_DEFAULT);
  const [isSaving, setIsSaving] = useState(false);
  const [isMapChanged, setIsMapChanged] = useState(false);

  useEffect(() => {
    const eventHandler = (event) => {
      if (isMapChanged) {
        var confirmationMessage =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';

        (event || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
    };
    window.addEventListener('beforeunload', eventHandler);

    return () => {
      window.removeEventListener('beforeunload', eventHandler);
    };
  }, [isMapChanged]);

  useEffect(() => {
    if (!loading && !!data) {
      if (!data.template || data.template.length <= 0) {
        templateCreate({ variables: { propertyId: property.id } })
          .then((result) => {
            setTemplate(result.data.template);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setTemplate(data.template[0]);
      }
    }
    // eslint-disable-next-line
  }, [data, property.id, loading]);

  let fetchLocation = function () {
    const city = get(property, 'city.name');
    const state = get(property, 'state.abbreviation');
    const addressLineOne = get(property, 'addressLineOne');

    if (addressLineOne && city && state) {
      const zipCode = get(property, 'zipCode');
      fetchData(
        FACILITY_LOCATION_GEOCOD_PATH.format({ addressLineOne, city, zipCode, state }),
        'GET',
      )
        .then((result) => {
          if (result?.results?.length > 0 && result?.results?.[0]?.location?.lat) {
            const apiLocation = {
              ...result?.results?.[0]?.location,
            };
            handleImageChange({ location: apiLocation });
            setDefaultCenter(apiLocation);
          } else {
            fetchData(FACILITY_LOCATION_PATH.format({ city, zipCode, state }), 'GET').then(
              (result) => {
                if (result?.length > 0 && result?.[0]?.lat) {
                  const apiLocation = { lat: get(result, '[0].lat'), lng: get(result, '[0].lon') };
                  handleImageChange({ location: apiLocation });
                  setDefaultCenter(apiLocation);
                }
              },
            );
          }
          setDefaultZoom(ZOOM_DEFAULT);
        })
        .catch((error) => {
          console.log('Error fetching client location', error);
          fetchData(FACILITY_LOCATION_PATH.format({ city, zipCode, state }), 'GET').then(
            (result) => {
              const apiLocation = { lat: get(result, '[0].lat'), lng: get(result, '[0].lon') };
              handleImageChange({ location: apiLocation });
              setDefaultCenter(apiLocation);
              setDefaultZoom(ZOOM_DEFAULT);
            },
          );
        });
    }
  };
  /**
   * Set the default values from the existing client.
   */
  useEffect(() => {
    if (!!property) {
      if (property.latitude || property.longitude) {
        setDefaultCenter({
          lat: property?.latitude,
          lng: property?.longitude,
        });
      } else {
        fetchLocation();
      }
    }
  }, [property]);

  /**
   * Handle changes to the selected tab.
   *
   * @param event The target of the event that triggered the change.
   * @param value The value of the change.
   */
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  const handleImageChange = async ({ image, location }) => {
    let mapImageS3Data;
    let latitude, longitude;

    if (image || location) {
      try {
        if (image) {
          const imageBucket = await uploadPhotoS3(image);
          mapImageS3Data = { imageBucket };
        }
        if (location) {
          latitude = +location.lat;
          longitude = +location.lng;
        }

        await propertyUpdate({
          variables: { id: property?.id, mapImageS3Data, latitude, longitude },
        });
      } catch (e) {
        console.log(e);
        setErrorId('property.canSaveImage.message');
        setErrorValues(e.message);
      }
    }
  };

  const handleSubmit = async (event) => {
    if (mapRef.current) {
      try {
        setIsSaving(true);
        const results = await mapRef.current.getImage();
        await handleImageChange(results);
        setIsMapChanged(false);
      } catch (e) {
        console.log('Error updating map', e);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleResetLocation = (event) => {
    fetchLocation();
  };

  const handleMapChange = () => {
    setIsMapChanged(true);
  };

  if (loading) {
    return statusComponent;
  }
  return (
    <Grid container item className={classes.root} wrap={'nowrap'} direction={'column'}>
      {statusComponent || templateCreateStatusComponent || propertyUpdateStatusComponent}
      {error && <DisplayError error={error} errorId={errorId} values={errorValues} />}
      <Prompt when={isSaving || isMapChanged} />
      {!isFullScreen && (
        <Grid item resizable={false}>
          <Tabs
            variant="scrollable"
            scrollButtons={false}
            value={selectedTab}
            onChange={handleChange}
            classes={{ indicator: classes.indicatorStyle }}
          >
            <Tab
              className={classes.tab}
              label={<Typography id="questionType.inclusive" variant={'h6'} color={'inherit'} />}
              value={INCLUSIVE_INSPECTION_TAB}
            />
            <Tab
              className={classes.tab}
              label={<Typography id="questionType.inspect" variant={'h6'} color={'inherit'} />}
              value={INSPECT_TAB}
            />
            <Tab
              className={classes.tab}
              label={<Typography id="questionType.audit" variant={'h6'} color={'inherit'} />}
              value={AUDIT_TAB}
            />
            <Tab
              className={classes.tab}
              label={<Typography id="questionType.powerwash" variant={'h6'} color={'inherit'} />}
              value={POWER_WASH_TAB}
            />
            <Tab
              className={classes.tab}
              label={<Typography variant={'h6'} color={'inherit'} id={'inspect.map.title'} />}
              value={MAP_TAB}
            />
            <Tab
              className={classes.tab}
              component={Link}
              label={
                <Typography variant={'h6'} color={'inherit'} id={'inspect.assets.button.label'} />
              }
              to={`/map/${property.id}`}
            />
            <Tab
              className={classes.tabNoBorder}
              component={Link}
              label={
                <Typography
                  variant={'h6'}
                  color={'inherit'}
                  id={'inspect.show.assets.button.label'}
                />
              }
              to={`/show-assets/${property.id}`}
            />
          </Tabs>
        </Grid>
      )}
      <Grid item resizable={true} style={{ paddingTop: 16, height: 'calc(100% - 80px)' }}>
        {selectedTab === INCLUSIVE_INSPECTION_TAB && (
          <AreaBuilder
            template={template}
            property={property}
            questionTypeId={INCLUSIVE_TYPE}
            reportId={reportId}
          />
        )}
        {selectedTab === INSPECT_TAB && (
          <AreaBuilder
            template={template}
            property={property}
            questionTypeId={INSPECTION_TYPE}
            reportId={reportId}
          />
        )}
        {selectedTab === AUDIT_TAB && (
          <AreaBuilder
            template={template}
            property={property}
            questionTypeId={AUDIT_TYPE}
            reportId={reportId}
          />
        )}
        {selectedTab === POWER_WASH_TAB && (
          <AreaBuilder
            template={template}
            property={property}
            questionTypeId={POWER_WASH_TYPE}
            reportId={reportId}
          />
        )}
        {selectedTab === MAP_TAB && (
          <Grid container item direction={'column'} wrap={'nowrap'} fullHeight>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <ProgressButton
                isProgress={isSaving}
                onClick={handleSubmit}
                color={'secondary'}
                classes={{ spinnerMargin: classes.spinnerMargin }}
              >
                <Typography color="inherit">
                  {property?.mapImageData ? 'Update' : 'Save'} Map
                </Typography>
              </ProgressButton>
              {(property?.latitude || property?.longitude) && (
                <Button
                  onClick={handleResetLocation}
                  color={'secondary'}
                  style={{ marginLeft: theme.spacing(1) }}
                >
                  <Typography color="inherit">Reset Location</Typography>
                </Button>
              )}
            </Grid>
            <MapFHG
              name="map"
              ref={mapRef}
              defaultCenter={defaultCenter}
              defaultZoom={defaultZoom}
              onChange={handleMapChange}
              disabled={isSaving}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

InspectionBuilder.propTypes = {
  property: PropTypes.object,
};

export default InspectionBuilder;
