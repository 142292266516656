import { MenuItem, TextField } from '@material-ui/core';
import {
  useClientImpersonationContextDispatch,
  useClientImpersonationContextState,
} from '../ClientImpersonationContext';
import { useGetClientsQuery } from '../generated/graphql';

export function ClientImpersonationDropdown(props) {
  const { data } = useGetClientsQuery();
  const dispatch = useClientImpersonationContextDispatch();
  const { clientId } = useClientImpersonationContextState();
  const clients = data?.clients ?? [];

  const { style = {}, className = '' } = props;

  const clientsOptions = clients.map((client) => {
    return client
      ? {
          value: client.id,
          label: client.name,
        }
      : null;
  });

  const handleChange = (e) => {
    const clientId = e.target.value;
    dispatch({ type: 'SET_CLIENT', payload: clientId });
  };

  return (
    <TextField
      className={className}
      onChange={handleChange}
      value={clientId}
      select
      variant="outlined"
      label="Impersonate Client"
      style={{ width: 200, ...style }}
    >
      <MenuItem value="">None</MenuItem>
      {clientsOptions.map((client) => {
        return client ? (
          <MenuItem key={client.value} value={client.value}>
            {client.label}
          </MenuItem>
        ) : null;
      })}
    </TextField>
  );
}
