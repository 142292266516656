import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { AddCircle } from '@material-ui/icons';
import gql from 'graphql-tag';
import { castArray } from 'lodash';
import { map, some, without, defer, find, isArray } from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams, Prompt } from 'react-router-dom';
import TextField from '../../components/TextField';
import {
  PROPERTY_OWNER_GROUP,
  INSPECTOR_GROUP,
  USERS_ALL_PATH,
  ALT_USER_GROUP,
} from '../../Constants';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import StyledDropZone from '../../fhg/components/data/StyledPhotoDropZone';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import ReactSelect from '../../fhg/components/ReactSelect';
import PasswordTextField from '../../fhg/components/security/PasswordTextField';
import Typography from '../../fhg/components/Typography';
import ValidateTarget from '../../fhg/components/ValidateTarget';
import { uploadFileS3 } from '../../fhg/utils/SubmitUtils';
import { convertImageToWrapper } from '../../fhg/utils/Utils';
import { formatMessage, toNumber } from '../../fhg/utils/Utils';
import { UPDATE_PROPERTY } from '../property/PropertyInfoEdit';
import { USER_REGION_QUERY } from '../property/PropertyView';
import { USERS_QUERY } from './UserView';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 400,
      '& .MuiOutlinedInput-input': {
        background: theme.palette.background.default,
        color: theme.palette.text.secondary,
      },
    },
    paperStyle: {
      margin: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
        height: `calc(100% - ${theme.spacing(2)}px)`,
      },
      maxWidth: 800,
      overflow: 'auto',
    },
    listFrame: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      // minHeight: 500,
      position: 'relative',
      height: '100%',
    },
    rightButtonStyle: {
      composes: '$buttonStyle',
      marginLeft: theme.spacing(1),
    },
    selectRoot: {
      marginTop: theme.spacing(1),
      flex: 1,
    },
  }),
  { name: 'UserInfoEditStyles' },
);

export const USER_FRAGMENT = gql`
  fragment userEditInfo on User {
    id
    cognitoSub
    isDeleted
    email
    firstName
    lastName
    licenseNumber
    signatureImageData {
      id: imageHash
      imageS3
    }
    credentials
    username
    roles
    regions: regionList {
      id
      name
      value: id
      label: name
    }
  }
`;

export const OWNER_USER_FRAGMENT = gql`
  fragment ownerUserEditInfo on User {
    id
    cognitoSub
    isDeleted
    email
    firstName
    lastName
    licenseNumber
    credentials
    username
    roles
  }
`;

export const OPTIONS_QUERY = gql`
  query getUserOptions {
    regions: region_All {
      id
      isDeleted
      name
      value: id
      label: name
    }
  }
`;

export const USER_FOR_PROPERTY_QUERY = gql`
  query getUserForProperty($propertyId: Int!) {
    property: property_ById(propertyId: $propertyId) {
      id
      userIdList
      userList {
        ...ownerUserEditInfo
      }
    }
    allPropertyUsers: user_AllWhere(userSearch: { roles: ["Property-Owner"] }) {
      ...ownerUserEditInfo
    }
    allAltUsers: user_AllWhere(userSearch: { roles: ["Alt-User"] }) {
      ...ownerUserEditInfo
    }
  }
  ${OWNER_USER_FRAGMENT}
`;

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String
    $username: String!
    $password: String
    $firstName: String!
    $lastName: String!
    $licenseNumber: String
    $credentials: String
    $roles: [String!]
    $propertyId: Int
    $propertyIdList: [Int]
    $regionIdList: [Int]
    $signatureImageS3Data: ImageS3Data
  ) {
    user: user_Create(
      user: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        password: $password
        licenseNumber: $licenseNumber
        credentials: $credentials
        email: $email
        roles: $roles
        propertyId: $propertyId
        propertyIdList: $propertyIdList
        regionIdList: $regionIdList
        signatureImageS3Data: $signatureImageS3Data
      }
    ) {
      ...userEditInfo
    }
  }
  ${USER_FRAGMENT}
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $email: String
    $username: String
    $password: String
    $firstName: String
    $lastName: String
    $licenseNumber: String
    $credentials: String
    $roles: [String!]
    $propertyId: Int
    $propertyIdList: [Int]
    $regionIdList: [Int]
    $signatureImageS3Data: ImageS3Data
  ) {
    user: user_Update(
      userId: $id
      user: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        password: $password
        licenseNumber: $licenseNumber
        credentials: $credentials
        email: $email
        roles: $roles
        propertyId: $propertyId
        propertyIdList: $propertyIdList
        regionIdList: $regionIdList
        signatureImageS3Data: $signatureImageS3Data
      }
    ) {
      ...userEditInfo
    }
  }
  ${USER_FRAGMENT}
`;

const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    user_Delete(userId: $id)
  }
`;

UserInfoEdit.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

/**
 * The New User dialog which creates a new user.
 */
export default function UserInfoEdit({ user, roles = [] }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const { id } = useParams();
  const { isCreate, property } = get(location, 'state', {});

  const { data, statusComponent } = useQueryFHG(USER_FOR_PROPERTY_QUERY, {
    variables: { propertyId: property && property.id },
    skip: !property || !property.id,
  });
  const { data: optionsData, optionsStatusComponent } = useQueryFHG(OPTIONS_QUERY);

  const [userDelete, userDeleteStatusComponent] = useMutationFHG(DELETE_USER);
  const [userCreate, userCreateStatusComponent] = useMutationFHG(CREATE_USER);
  const [userUpdate, userUpdateStatusComponent] = useMutationFHG(UPDATE_USER);
  const [propertyUpdate, propertyUpdateStatusComponent] = useMutationFHG(UPDATE_PROPERTY);

  const [
    editValues,
    handleChange,
    {
      handleSelectChange,
      defaultValues,
      setDefaultValues,
      isChanged,
      setIsChanged,
      setEditValues,
      resetValues,
    },
  ] = useEditData(user || {});
  const [editUsers, setEditUsers] = useState([]);
  const [isUsersChanged, setIsUsersChanged] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [isNew, setIsNew] = useState(true);

  const userRoles = user?.roles ?? [];
  const isCurrentlyClientAdmin = user.clientId && userRoles.includes('Admin') ? true : false;

  useEffect(() => {
    if (isCreate) {
      resetValues();
      if (!!property) {
        setEditUsers([]);
      }
    }
  }, [isCreate, property, resetValues]);

  const getPropertyDefaults = useCallback(() => {
    const newEditValues = { email: property.email };

    if (property.contactName) {
      const splitName = property.contactName.split(' ');
      newEditValues.username = splitName.join('.');

      if (splitName.length >= 2) {
        newEditValues.firstName = splitName[splitName.length - 2];
        newEditValues.lastName = splitName[splitName.length - 1];
        newEditValues.username = (
          newEditValues.firstName +
          '.' +
          newEditValues.lastName
        ).toLowerCase();
      } else if (splitName.length === 1) {
        newEditValues.firstName = splitName[0];
        newEditValues.lastName = '-';
        newEditValues.username = newEditValues.firstName;
      }
    } else {
      newEditValues.username = property.name ? property.name.replace(' ', '.') : undefined;
    }
    return newEditValues;
  }, [property?.contactName, property?.email, property?.name]);

  useEffect(() => {
    if ((!!property && data) || (!property && !!user)) {
      if (!!property && data) {
        const existingUsers = get(data, 'property.userList') || [];
        setEditUsers(existingUsers);

        const existingUser = find(existingUsers, { id: toNumber(id) }) || get(existingUsers, '[0]');
        if (existingUser) {
          setEditValues({});
          setDefaultValues(existingUser);
          setIsNew(false);
        } else {
          resetValues();
          setEditValues({
            ...getPropertyDefaults(),
            roles: [{ label: PROPERTY_OWNER_GROUP, value: PROPERTY_OWNER_GROUP }],
          });
          setIsNew(true);
        }
      } else if (!!user) {
        setEditValues({ ...user, roles: undefined });
        setIsNew(!user.id);
      } else {
        setEditValues({ roles: [] });
        setIsNew(true);
      }
    }
  }, [user, property, data, id, setEditValues, setDefaultValues, resetValues, getPropertyDefaults]);

  /**
   * Submits the changes to the user.
   */
  const handleSubmit = async () => {
    let result;

    if (!isSaving) {
      try {
        setIsSaving(true);
        if (isChanged) {
          let signatureImageS3Data;
          if (editValues.image) {
            const imageBucket = await uploadFileS3(editValues.image);
            signatureImageS3Data = { imageBucket };
            editValues.image = undefined;
          } else if (editValues.image === null) {
            signatureImageS3Data = null;
            editValues.image = undefined;
          }
          const variables = {
            ...editValues,
            regions: undefined,
            regionIdList: map(editValues.regions, 'id'),
            signatureImageS3Data,
            roles_Edit: undefined,
            regions_Edit: undefined,
            users_Edit: undefined,
          };

          if (!isArray(editValues.roles)) {
            variables.roles = editValues.roles ? [get(editValues.roles, 'value')] : undefined;
          } else {
            // No property is selected, so add the changes to the user.
            variables.roles =
              editValues.roles !== undefined ? map(editValues.roles, 'value') : undefined;
          }

          if (editValues.id) {
            await userUpdate({ variables, refetchQueries: getRefetchQueries });
          } else {
            result = await userCreate({
              variables,
              refetchQueries: getRefetchQueries,
            });
          }
          setIsChanged(false);
        }
        if (!!property && (isUsersChanged || result)) {
          // Add existing selected users to the property
          const userIdList = map(editUsers, 'id');

          // Add the new user if there is one.
          if (result) {
            userIdList.push(get(result, 'data.user.id'));
          }

          await propertyUpdate({
            variables: { id: property.id, userIdList },
            refetchQueries: getPropertyRefetchQueries,
          });
          setIsUsersChanged(false);
        }

        setIsSaving(false);
        if (!id || toNumber(id) !== editValues.id) {
          defer(() => {
            history.push(`/user/${get(result, 'data.user.id', editValues.id)}`, {
              property,
            });
          });
        }
      } catch (error) {
        setIsSaving(false);
      }
    }
  };

  const getRefetchQueries = () => {
    const propertyQueries =
      user &&
      user.propertyIdList &&
      user.propertyIdList.map((propertyId) => ({
        query: USER_FOR_PROPERTY_QUERY,
        variables: { propertyId },
      }));
    return [
      {
        query: USERS_QUERY,
      },
      {
        query: USER_REGION_QUERY,
        variables: { cognitoSub: user.cognitoSub },
      },
      ...(propertyQueries || []),
    ];
  };

  const getPropertyRefetchQueries = () => {
    return [
      {
        query: USER_FOR_PROPERTY_QUERY,
        variables: { propertyId: property && property.id },
      },
    ];
  };

  const handleDelete = () => {
    userDelete({
      variables: { id: get(editValues, 'id', get(defaultValues, 'id')) },
      refetchQueries: getRefetchQueries,
    });
    setIsChanged(false);

    if (!!property) {
      if (editUsers.length <= 1) {
        history.push(USERS_ALL_PATH, { isCreate: true, property });
      } else {
        history.push(location.pathname, { isCreate: false, property });
      }
    } else {
      history.push(USERS_ALL_PATH);
    }
  };

  const setUser = (user) => {
    setDefaultValues({ ...user });
    setEditValues({});
    setIsChanged(false);
    setIsNew(false);
  };

  const handleMultiClick = (event, user) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isChanged) {
      setUser(user);
    } else {
      setShowConfirm(user);
    }
  };

  const handleUserChange = (userList, name, isMulti, options) => {
    if (userList && userList.length !== undefined) {
      setEditUsers(userList || []);
      setIsUsersChanged(true);
      const selectedUser = userList && userList.length > 0 && userList[userList.length - 1];
      if (!isChanged && selectedUser) {
        setUser(selectedUser);
      }
    } else if (userList === null && options?.action === 'remove-value') {
      setEditUsers([]);
      setIsUsersChanged(true);
      resetValues();
      setEditValues({
        ...getPropertyDefaults(),
        roles: [{ label: PROPERTY_OWNER_GROUP, value: PROPERTY_OWNER_GROUP }],
      });
      setIsNew(true);
    }
  };

  const handleConfirm = () => {
    setUser(showConfirm);
    handleClose();
  };

  const handleClose = () => {
    setShowConfirm(undefined);
  };

  const handleCreateNewUser = () => {
    setDefaultValues({ roles: [PROPERTY_OWNER_GROUP] });
    setEditValues({});
    setIsNew(true);
    // history.push(USERS_ALL_PATH, {isCreate: true, property});
  };

  /**
   * Handle onChange events for the inputs.
   *
   * NOTE:
   * Input components MUST have their name set to be set in the editValues.
   *
   * @param event The event that changed the input.
   * @param value The value if the component is an Autocomplete
   * @param name
   * @param reason The reason of the value change if Autocomplete
   */
  const handleChangeCallback = useCallback(
    (event, value, reason, newValue, name) => {
      handleChange(event, value, reason, newValue, name);

      if (name === 'password') {
        const target = document.getElementById('confirm_password');
        if (target) {
          target.setCustomValidity(
            this.state.confirm !== this.state.password
              ? formatMessage(
                  intl,
                  'user.confirmMismatch.message',
                  'Confirm does not match the password.',
                )
              : '',
          );
        }
      }
    },
    [handleChange, intl],
  );

  const handleDrop = useCallback(
    (newImage, reject) => {
      if (editValues) {
        setEditValues({ ...editValues, image: newImage });
        setIsChanged(true);
      }
    },
    [editValues, setEditValues, setIsChanged],
  );

  const isInspector =
    editValues && editValues.roles !== undefined
      ? some(editValues.roles, { value: INSPECTOR_GROUP })
      : some((user && user.roles) || [], (role) => role === INSPECTOR_GROUP);
  const isPropertyRole =
    !!property ||
    get(user, 'propertyIdList.length') > 0 ||
    (editValues && editValues.roles !== undefined
      ? get(editValues, 'roles.value') === ALT_USER_GROUP ||
        get(editValues, 'roles.value') === PROPERTY_OWNER_GROUP
      : some(
          (user && user.roles) || [],
          (role) => role === PROPERTY_OWNER_GROUP || role === ALT_USER_GROUP,
        ));
  const defaultRoles = get(defaultValues, 'roles') || [];
  const useRolesOptions =
    !property && !isPropertyRole
      ? without(roles, PROPERTY_OWNER_GROUP, ALT_USER_GROUP)
      : [PROPERTY_OWNER_GROUP, ALT_USER_GROUP];

  if (!isNew && (!editValues || !editValues.username) && !defaultValues.username) {
    return (
      statusComponent ||
      userCreateStatusComponent ||
      userUpdateStatusComponent ||
      userDeleteStatusComponent ||
      propertyUpdateStatusComponent ||
      null
    );
  }
  const useUser = defaultValues.firstName ? defaultValues : user;

  return (
    <Form submit={handleSubmit}>
      {optionsStatusComponent}
      <Prompt when={isChanged || isUsersChanged} message={formatMessage(intl, 'leavePage')} />
      {showConfirm && (
        <ModalDialog
          submitKey={'user.deleteChanges.button'}
          titleKey={'user.deleteChanges.button'}
          messageKey={'user.deleteChanges.message'}
          onSubmit={handleConfirm}
          onClose={handleClose}
          hideBackdrop
          submitColor="secondary"
        />
      )}

      <Grid container direction={'column'} wrap="nowrap" className={classes.root}>
        {statusComponent ||
          userCreateStatusComponent ||
          userUpdateStatusComponent ||
          userDeleteStatusComponent ||
          propertyUpdateStatusComponent}
        <Paper square className={classes.paperStyle}>
          <Grid container direction={'column'} wrap="nowrap" className={classes.listFrame}>
            <Grid item container direction={'row'} justify={'space-between'}>
              <Grid item>
                <Typography variant={'h6'} color={'primary'}>
                  {!isCreate
                    ? useUser.firstName
                      ? `${useUser.firstName} ${useUser.lastName}`
                      : useUser.username
                    : 'New User'}
                  {!!property && ` - ${property.name}`}
                </Typography>
              </Grid>
              {isCurrentlyClientAdmin ? (
                <Grid item>
                  <ConfirmButton
                    onConfirm={handleDelete}
                    buttonLabelKey={'user.delete.button'}
                    style={{
                      padding: 6,
                      color: theme.palette.primary.dark,
                      background: theme.palette.background.dark,
                    }}
                    color={theme.palette.error.dark}
                    messageKey={'user.delete.clientAdminConfirmation'}
                    titleKey={'user.delete.title'}
                    values={{ ...editValues, ...defaultValues }}
                    confirmProps={{
                      submitColorStyle: classes.deleteColorStyle,
                    }}
                  />
                </Grid>
              ) : (
                !isCreate &&
                !isNew && (
                  <Grid item>
                    <ConfirmButton
                      onConfirm={handleDelete}
                      buttonLabelKey={'user.delete.button'}
                      style={{
                        padding: 6,
                        color: theme.palette.text.secondary,
                        background: theme.palette.background.default,
                      }}
                      color={theme.palette.error.dark}
                      messageKey={'user.delete.confirmation'}
                      titleKey={'user.delete.title'}
                      values={{ ...editValues, ...defaultValues }}
                      confirmProps={{
                        submitColorStyle: classes.deleteColorStyle,
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
            <Grid container spacing={2}>
              {!!property && (
                <Grid item container xs={12}>
                  <Grid item resizable>
                    <ReactSelect
                      name={'users'}
                      label={<Typography display={'inline'} id={'user.owners.label'} />}
                      classes={{ root: classes.selectRoot }}
                      onMultiClick={handleMultiClick}
                      openMenuOnClick={false}
                      options={[
                        ...(get(data, 'allPropertyUsers') || []),
                        ...(get(data, 'allAltUsers') || []),
                      ]}
                      getOptionLabel={(option) =>
                        option.label ? option.label : `${option.firstName} ${option.lastName}`
                      }
                      getLabel={(option) =>
                        option.lastName || option.firstName
                          ? `${option.firstName} ${option.lastName}`
                          : 'Untitled'
                      }
                      defaultValue={get(data, 'property.userList') || []}
                      value={editUsers}
                      valueKey={'id'}
                      onChange={handleUserChange}
                      isMulti
                      fullWidth
                      margin="none"
                      disabled={isSaving}
                    />
                  </Grid>
                  <Grid item resizable={false}>
                    <IconButton onClick={handleCreateNewUser} style={{ marginTop: 10 }}>
                      <AddCircle color={'primary'} />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label={'First Name'}
                  required
                  autoFocus
                  fullWidth
                  value={
                    (editValues.firstName !== undefined
                      ? editValues.firstName
                      : defaultValues.firstName) || ''
                  }
                  onChange={handleChange}
                  margin="normal"
                  style={{ marginBottom: 0 }}
                  disabled={isSaving}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label={'Last Name'}
                  required
                  fullWidth
                  value={
                    (editValues.lastName !== undefined
                      ? editValues.lastName
                      : defaultValues.lastName) || ''
                  }
                  onChange={handleChange}
                  margin="normal"
                  style={{ marginBottom: 0 }}
                  disabled={isSaving}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  type="email"
                  label={'Email'}
                  fullWidth
                  value={
                    (editValues.email !== undefined ? editValues.email : defaultValues.email) || ''
                  }
                  onChange={handleChange}
                  margin="none"
                  disabled={isSaving}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="username"
                  type="username"
                  label={'Username'}
                  fullWidth
                  value={
                    (editValues.username !== undefined
                      ? editValues.username
                      : defaultValues.username) || ''
                  }
                  onChange={handleChange}
                  margin="none"
                  disabled={isSaving || editValues.id}
                  required
                />
              </Grid>
              <PasswordTextField
                name="password"
                fullWidth
                isNew={isNew}
                disabled={isSaving}
                onChange={handleChangeCallback}
                password={editValues.password}
                confirm={editValues.confirm}
              />
              {(!property || !isPropertyRole) && (
                <Grid item container xs={12}>
                  <ReactSelect
                    name={'regions'}
                    label={<Typography display={'inline'} id={'user.regions.label'} />}
                    options={get(optionsData, 'regions')}
                    defaultValue={get(defaultValues, 'regions')}
                    value={editValues.regions}
                    placeholder={'Enter regions or leave empty for all regions'}
                    onChange={handleSelectChange}
                    isMulti
                    fullWidth
                    margin="none"
                    disabled={isSaving}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ReactSelect
                  key={defaultRoles?.toString()}
                  name={'roles'}
                  label={<Typography display={'inline'} id={'user.roles.label'} />}
                  options={ReactSelect.createOptions(useRolesOptions)}
                  defaultValue={ReactSelect.createOptions(castArray(defaultRoles))}
                  value={editValues.roles}
                  onChange={handleSelectChange}
                  isMulti={!isPropertyRole}
                  fullWidth
                  margin="none"
                  disabled={isSaving}
                  required
                />
                {!(editValues.roles !== undefined
                  ? editValues.roles && castArray(editValues.roles).length > 0
                  : defaultRoles && castArray(defaultRoles)?.length > 0) && (
                  <ValidateTarget name={'ValidateTargetRoles'} top={-1} value={undefined} />
                )}
              </Grid>
              {isInspector && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="licenseNumber"
                      type="licenseNumber"
                      label={'License Number'}
                      fullWidth
                      value={
                        (editValues.licenseNumber !== undefined
                          ? editValues.licenseNumber
                          : defaultValues.licenseNumber) || ''
                      }
                      onChange={handleChange}
                      margin="none"
                      disabled={isSaving}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="credentials"
                      type="credentials"
                      label={'Credentials'}
                      fullWidth
                      value={
                        (editValues.credentials !== undefined
                          ? editValues.credentials
                          : defaultValues.credentials) || ''
                      }
                      onChange={handleChange}
                      margin="none"
                      disabled={isSaving}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledDropZone
                      accept={['image/jpeg', 'image/png']}
                      image={
                        user?.image?.blob ||
                        convertImageToWrapper(user?.signatureImageData?.imageS3)
                      }
                      onDrop={handleDrop}
                      labelKey="signature.label"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                style={{
                  marginTop: 16,
                  color: theme.palette.background.paper,
                  background: theme.palette.primary.main,
                }}
                variant="contained"
                variant="contained"
              >
                <Typography color="inherit" id={'save.label'} />
                {isSaving && (
                  <CircularProgress className={classes.spinnerStyle} size={15} thickness={2.5} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Form>
  );
}
