import React, { useMemo, useRef, useState } from 'react';
import { Marker } from 'react-leaflet';
import { Tooltip, Popup } from 'react-leaflet';

export default function DraggableMarker({
  location,
  isDraggable = true,
  onDragEnd,
  onChange,
  label,
  showPopup,
  icon,
  customTooltip,
}) {
  const markerRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        onChange?.(marker);
      },
    }),
    [onChange]
  );

  const handleMouseOver = () => {
    if (!customTooltip) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    if (!customTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <Marker
      ref={markerRef}
      draggable={isDraggable}
      eventHandlers={eventHandlers}
      position={location}
      onDragEnd={onDragEnd}
      icon={icon}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {customTooltip ? <Popup>{customTooltip}</Popup> : null}
      {!customTooltip && showTooltip && label !== '-' && (
        <Tooltip direction="right" offset={[-8, -2]} opacity={1} permanent>
          <span>{label || 'Playground Location'}</span>
        </Tooltip>
      )}
    </Marker>
  );
}
