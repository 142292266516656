import Badge from '@material-ui/core/Badge/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import { find, filter, differenceBy } from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import useLazyQueryFHG from '../../fhg/components/data/useLazyQueryFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import { PROPERTY_BY_ID_QUERY } from '../inspect/InspectionView';
import { QUESTION_ALIAS_FRAGMENT } from '../Types';
import { TEMPLATE_QUERY } from './InspectionBuilder';
import ItemMenu from './ItemMenu';
import ItemMenuLazy from './ItemMenuLazy';

const useStyles = makeStyles((theme) => ({
  extraLargeLeftSpace: {
    // marginLeft: theme.spacing(2),
  },
  actionButtonStyle: {
    minWidth: 'max-content',
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(2),
  },
}));
export const ExpansionPanelSummary = withStyles({
  root: {
    // backgroundColor: '#',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const ANSWER_FRAGMENT = gql`
  fragment answerInfo on Answer {
    id
    isDeleted
    equipment {
      id
      isDeleted
      name
    }
    imageData {
      imageS3
      imageFilename
    }
    note
    priorityId
    question {
      id
      isDeleted
      text
    }
  }
`;

const ANSWER_CREATE = gql`
  mutation AnswerCreateForQuestionBuilder($reportId: Int!, $questionAliasId: Int!) {
    answer: answer_Create(answer: { reportId: $reportId, questionAliasId: $questionAliasId }) {
      ...answerInfo
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const QUESTION_ALIAS_CREATE = gql`
  mutation QuestionCreate($questionId: Int!, $equipmentId: Int!, $markedForAdd: Boolean) {
    questionAlias: questionAlias_Create(
      questionAlias: {
        equipmentId: $equipmentId
        questionId: $questionId
        markedForAdd: $markedForAdd
      }
    ) {
      ...questionAliasInfo
    }
  }
  ${QUESTION_ALIAS_FRAGMENT}
`;

export const QUESTION_ALIAS_UPDATE = gql`
  mutation QuestionUpdate(
    $id: Int!
    $questionId: Int
    $equipmentId: Int
    $markedForAdd: Boolean
    $markedForRemove: Boolean
  ) {
    questionAlias: questionAlias_Update(
      questionAliasId: $id
      questionAlias: {
        equipmentId: $equipmentId
        questionId: $questionId
        markedForAdd: $markedForAdd
        markedForRemove: $markedForRemove
      }
    ) {
      ...questionAliasInfo
    }
  }
  ${QUESTION_ALIAS_FRAGMENT}
`;

export const QUESTION_ALIAS_DELETE = gql`
  mutation questionAliasDelete($id: Int!) {
    questionAlias_Delete(questionAliasId: $id)
  }
`;

export const QUESTION_ALIAS_UNDELETE = gql`
  mutation questionAliasUndelete($id: Int!) {
    questionAlias_UnDelete(questionAliasId: $id)
  }
`;

const QUESTION_ALIAS_DELETED_QUERY = gql`
  query getUndeletedQuestionAliases($equipmentId: [Int]) {
    deletedQuestions: questionAlias_AllWhere(
      includeDeleted: true
      questionAliasSearch: { equipmentId: $equipmentId, isDeleted: [true] }
    ) {
      id
      questionId
      question {
        id
        text
        isDeleted
      }
      isDeleted
      markedForAdd
      markedForRemove
    }
  }
`;

const QUESTION_QUERY = gql`
  query getQuestions($questionId: [Int]) {
    questions: question_AllWhere(includeDeleted: true, questionSearch: { id: $questionId }) {
      id
      name
      description
    }
  }
`;

/**
 * Drawer to navigate to reports and other screens.
 */
function QuestionBuilder({
  property,
  area,
  equipment,
  masterEquipment,
  propertyQuestionAliases,
  questionTypeId,
  reportId,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElUndelete, setAnchorElUndelete] = useState();
  const [masterQuestions, setMasterQuestions] = useState([]);
  const [questionAliases, setQuestionAliases] = useState([]);

  const [questionAliasCreate, createQuestionAliasStatusComponent] =
    useMutationFHG(QUESTION_ALIAS_CREATE);
  const [questionAliasUpdate, updateQuestionAliasStatusComponent] =
    useMutationFHG(QUESTION_ALIAS_UPDATE);
  const [questionAliasDelete, deleteQuestionAliasStatusComponent] =
    useMutationFHG(QUESTION_ALIAS_DELETE);

  const [loadDeletedQuestions, { questionAliasData, questionAliasLoading }] = useLazyQueryFHG(
    QUESTION_ALIAS_DELETED_QUERY,
    { fetchPolicy: 'no-cache' },
    'questionAlias',
  );

  const [undeleteQuestionAlias, undeleteQuestionAliasComponent] =
    useMutationFHG(QUESTION_ALIAS_UNDELETE);
  const [answerCreate, answerCreateStatus] = useMutationFHG(ANSWER_CREATE);

  const [loadTemplate, { templateData, templateStatusComponent, templateLoading }] =
    useLazyQueryFHG(TEMPLATE_QUERY, { fetchPolicy: 'cache-and-network' }, 'template');

  useEffect(() => {
    let masterQuestions = [];

    if (!!masterEquipment) {
      const masterEquipmentSingle = find(masterEquipment, { name: equipment.name });
      if (!!masterEquipmentSingle) {
        if (!questionTypeId) {
          masterQuestions = masterEquipmentSingle.questions;
        } else {
          masterQuestions = filter(masterEquipmentSingle.questions, { questionTypeId });
        }
      }
    }
    setMasterQuestions(masterQuestions);
  }, [masterEquipment, equipment.name, questionTypeId, reportId]);

  useEffect(() => {
    let useFilter = { isDeleted: false };

    if (questionTypeId) {
      useFilter = (questionAlias) =>
        !questionAlias.isDeleted &&
        get(questionAlias, 'question.questionTypeId') === questionTypeId;
    }
    const newQuestions =
      propertyQuestionAliases && propertyQuestionAliases.length > 0
        ? filter(propertyQuestionAliases, useFilter)
        : [];
    setQuestionAliases(newQuestions);
    // eslint-disable-next-line
  }, []);

  const getTemplateRefetchQueries = () => {
    return [{ query: TEMPLATE_QUERY, variables: { propertyId: property.id } }];
  };

  const getReportRefetchQueries = () => {
    return [
      {
        query: PROPERTY_BY_ID_QUERY,
        variables: { id: property.id, reportId: reportId },
        skip: !reportId,
        fetchPolicy: 'cache-and-network',
      },
    ];
  };

  const getRefetchQueries = () => {
    const refetchQueries = [
      {
        query: TEMPLATE_QUERY,
        variables: { propertyId: property.id },
        fetchPolicy: 'cache-and-network',
      },
    ];
    if (reportId) {
      refetchQueries.push({
        query: PROPERTY_BY_ID_QUERY,
        variables: { id: property.id, reportId: reportId },
        fetchPolicy: 'cache-and-network',
      });
    }
  };

  const handleDelete = (questionAlias) => (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!reportId) {
      questionAliasDelete({
        variables: { id: questionAlias.id },
        refetchQueries: getTemplateRefetchQueries,
      });
    } else {
      questionAliasUpdate({
        variables: { id: questionAlias.id, markedForRemove: true },
        refetchQueries: getRefetchQueries,
      });
    }
    setQuestionAliases(differenceBy(questionAliases, [{ id: questionAlias.id }], 'id'));
  };

  const handleAdd = async (addedQuestions = []) => {
    let newAliases = [];
    const isInspection = !!reportId;
    for (const question of addedQuestions) {
      const result = await questionAliasCreate({
        variables: {
          questionId: question.id,
          equipmentId: equipment?.id,
          markedForAdd: isInspection,
        },
        refetchQueries: getTemplateRefetchQueries,
      });
      if (isInspection) {
        answerCreate({
          variables: {
            reportId,
            questionAliasId: result.data.questionAlias.id,
          },
          refetchQueries: getReportRefetchQueries,
        });
      }
      newAliases.push({ id: result.data.questionAlias.id, question });
    }
    setQuestionAliases([...questionAliases, ...newAliases]);
  };

  const handleUndelete = async (undeletedQuestionAliases = []) => {
    // let newAliases = [];
    // const isInspection = !!reportId;
    for (const question of undeletedQuestionAliases) {
      const result = await undeleteQuestionAlias({
        variables: { id: question.id },
        refetchQueries: getTemplateRefetchQueries,
      });
      // newAliases.push({id: result.data.questionAlias.id, question});
    }
    setQuestionAliases([...questionAliases, ...undeletedQuestionAliases]);
  };

  const handleAction = (questionAlias, approve) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    if ((approve && questionAlias.markedForAdd) || (!approve && questionAlias.markedForRemove)) {
      questionAlias.markedForAdd = false;
      questionAlias.markedForRemove = false;
      questionAliasUpdate({
        variables: { id: questionAlias.id, markedForAdd: false, markedForRemove: false },
        refetchQueries: getRefetchQueries,
      });
    } else if (
      (!approve && questionAlias.markedForAdd) ||
      (approve && questionAlias.markedForRemove)
    ) {
      handleDelete(questionAlias)(null);
    }
    equipment.__approveCount--;
    if (equipment.__approveCount <= 0) {
      area.__approveCount--;
    }
  };

  const handleOpenAddQuestions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenUndeleteQuestions = (event) => {
    setAnchorElUndelete(event.currentTarget);
    handleLoadDeletedQuestions();
  };

  const handleCloseAddQuestions = (event) => {
    setAnchorEl(undefined);
  };

  const handleCloseUndeleteQuestions = (event) => {
    setAnchorElUndelete(undefined);
  };

  const handleLoadDeletedQuestions = useCallback(() => {
    if (equipment) {
      loadDeletedQuestions({ variables: { equipmentId: equipment?.id } });
    } else {
      console.log('Equipment was not set for undelete');
    }
  }, [loadDeletedQuestions]);

  const unusedMasterQuestions = useMemo(() => {
    const questionIds = questionAliases.map((alias) => ({ id: get(alias, 'question.id', -1) }));
    return differenceBy(masterQuestions, questionIds, 'id');
  }, [masterQuestions, questionAliases]);

  return (
    <Grid item fullWidth>
      {createQuestionAliasStatusComponent ||
        updateQuestionAliasStatusComponent ||
        deleteQuestionAliasStatusComponent ||
        answerCreateStatus}
      {questionAliases.map((questionAlias) => {
        return (
          <ExpansionPanel key={`QuestionProperty ${questionAlias.id}`} expanded={false}>
            <ExpansionPanelSummary
              key={`QuestionSummary ${questionAlias.id}`}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid
                container
                direction={'row'}
                wrap={'nowrap'}
                alignItems={'center'}
                justify={'space-between'}
                overflow={'visible'}
              >
                <Grid item resizable={true} overflow={'visible'}>
                  <Badge
                    color="secondary"
                    invisible={
                      !!reportId || !(questionAlias.markedForAdd || questionAlias.markedForRemove)
                    }
                    variant="dot"
                    className={classes.margin}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                  >
                    <Typography className={classes.extraLargeLeftSpace}>
                      {get(questionAlias, 'question.text')}
                    </Typography>
                  </Badge>
                </Grid>
                {!reportId && (
                  <Grid item resizable={false}>
                    {questionAlias.markedForAdd || questionAlias.markedForRemove ? (
                      <Fragment>
                        <Button
                          onClick={handleAction(questionAlias, true)}
                          color={'default'}
                          variant="contained"
                          className={classes.actionButtonStyle}
                        >
                          <Typography
                            color="inherit"
                            id={
                              questionAlias.markedForAdd
                                ? 'propertyQuestions.approveAdd.button'
                                : 'propertyQuestions.approveRemove.button'
                            }
                          />
                        </Button>
                        <Button
                          onClick={handleAction(questionAlias, false)}
                          color={'default'}
                          variant="contained"
                          className={classes.actionButtonStyle}
                        >
                          <Typography color="inherit" id={'propertyQuestions.notApprove.button'} />
                        </Button>
                      </Fragment>
                    ) : (
                      <Button
                        onClick={handleDelete(questionAlias)}
                        color={'default'}
                        variant="contained"
                        className={classes.actionButtonStyle}
                      >
                        <Typography color="inherit" id={'propertyQuestions.delete.button'} />
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        );
      })}
      <Grid item container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Button
            onClick={handleOpenAddQuestions}
            color={'primary'}
            variant="contained"
            style={{ margin: 16 }}
            disabled={unusedMasterQuestions.length <= 0}
          >
            <Typography color="inherit">Add Questions</Typography>
          </Button>
          <ItemMenu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseAddQuestions}
            items={unusedMasterQuestions}
            onAddItems={handleAdd}
            itemName={'Questions'}
            nameProperty={'text'}
            useAddAll={true}
          />
        </Grid>
        <Grid item>
          {/* <Button onClick={handleOpenUndeleteQuestions} color={'secondary'} style={{marginLeft: 16}}>
                  <Typography color='inherit'>Restore Removed Questions</Typography>
                  {questionAliasLoading && <CircularProgress className={classes.spinnerMargin} color='secondary' size={15} thickness={2.5}/>}
               </Button> */}
          <ItemMenuLazy
            open={!!anchorElUndelete}
            anchorEl={anchorElUndelete}
            operationName={'Restore'}
            onClose={handleCloseUndeleteQuestions}
            items={questionAliasData?.deletedQuestions}
            onAddItems={handleUndelete}
            itemName={'Questions'}
            nameProperty={'question.text'}
            useAddAll={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

QuestionBuilder.propTypes = {
  property: PropTypes.object,
  area: PropTypes.object,
  equipment: PropTypes.object,
  masterEquipment: PropTypes.array,
  propertyQuestionAliases: PropTypes.array,
};

QuestionBuilder.defaultProps = {
  propertyQuestionAliases: [],
};

export default QuestionBuilder;
