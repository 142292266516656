import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import {isNil} from 'lodash';
import React from 'react';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles({
   textFieldStyle: {
      '& label': {
         zIndex: 1,
      },
   },
}, {name: 'TextFieldStyles'});

/**
 * The TextField with preset formats.
 */
export default function FHGTextField({className, value, defaultValue, margin, label, labelKey, ...textFieldProps}) {
   const classes = useStyles();

   return (
      <TextField
         variant='outlined'
         {...textFieldProps}
         value={!isNil(value) ? value : !isNil(defaultValue) ? defaultValue : ''}
         label={labelKey ? (<Typography variant='inherit' id={labelKey}>{label}</Typography>) : label}
         className={`${classes.textFieldStyle} ${className}`}
         margin={margin || 'normal'}
      />
   );
}
