// Dates displayed to the user.
export const DATE_FORMAT = 'M/d/yyyy hh:mm a';
export const DATE_FORMAT_FILE = 'M_d_yyyy hh_mm a';
export const DATE_SHORT_FORMAT = 'MM/dd/yyyy';
export const DATE_AUDIT_FORMAT = 'MMMM do, yyyy';
export const DATE_FORMAT_KEYBOARD = DATE_SHORT_FORMAT;
export const DATE_TIME_DB_FORMAT = 'yyyy-MM-dd hh:mm (zzz)';

export const DATE_ONLY_FORMAT = 'M/d/yyyy';
export const TIME_FORMAT = 'hh:mm a';
export const DATE_LONG_FORMAT = 'M/d/yyyy hh:mm:ss a';
export const DATE_MED_FORMAT = 'M/d/yyyy hh:mm a';
export const PLACEHOLDER_IMAGE = '/images/placeholder.png';
export const AUDIT_ICON = '/images/audit-icon-16.png';
export const AUDIT_LARGE_ICON = '/images/audit-icon-32.png';
export const POWER_WASH_ICON = '/images/power-wash-icon-32.png';
export const INVENTORY_LARGE_ICON = '/images/inventory-icon-32.png';
export const INSPECT_ICON = '/images/inspect-icon-16.png';
export const INSPECT_LARGE_ICON = '/images/inspect-icon-32.png';
export const INCLUSIVE_ICON = '/images/inclusive-icon-16.png';
export const INCLUSIVE_LARGE_ICON = '/images/inclusive-icon-32.png';
export const MAP_MARKER_ICON = '/images/map-marker-icon-16.png';
export const MAP_FOOTBALL_ICON = '/images/football.png';
export const MAP_PARK_ICON = '/images/park.png';
export const MAP_PIN_ICON = '/images/pin.png';
export const MAP_WRENCH_ICON = '/images/wrench.png';
export const MAP_STAR_ICON = '/images/star.png';
export const SEPARATOR = '/images/line.png';
export const LOGO_LARGE = '/images/PPT Horiz Color.png';
export const CHART_IMAGE = '/images/Accessibility.png';
export const CHART_TEXT_IMAGE = '/images/Accessibility_Text.png';
export const VISIBLE_ICON = '/images/visible.png';
export const VISIBILITY_ICON = '/images/visibility.png';

export const DAYS_SINCE_COMPLETION = 7;

export const CAMERA_SOUND = '/camera.mp3';
export const CAMERA_VOLUME = 0.02;

// Delay before the changes are saved to the server.
export const SUBMIT_ANSWER = 3000;

// Auto hide the expand with unanswered questions message.
export const HIDE_EXPAND_MESSAGE = 4000;

export const LOGO_SMALL = '/images/PPT Shirt Logo.jpg';
export const LOGO_SMALL_DARK = '/images/PPT-White-800x200-5.png';
export const LOGIN_HEADER_BACKGROUND = '/images/PPT_Solutions.png';
export const LOGIN_PAGE_GRAPHIC = '/images/Login_Page_Graphic.jpg';

export const MAX_CSV_ROWS = 18000;

export const drawerWidth = 240;

export const HOST = process.env.REACT_APP_ENDPOINT;
export const ENDPOINT = !process.env.REACT_APP_ENDPOINT
  ? '/api/graphql/'
  : `https://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Version = ', process.env.REACT_APP_VERSION);
console.log('Host = ', process.env.REACT_APP_ENDPOINT);

export const MIN_ZOOM_DEFAULT = 2;
export const MAX_ZOOM_DEFAULT = 20;
export const ZOOM_DEFAULT = 18;

export const HEADER_HEIGHT = 64;
export const APPBAR_HEIGHT = 65;
export const APPBAR_SMALL_HEIGHT = 60;

export const THUMBNAIL_SIZE = 145;
export const DEFAULT_EMAIL = 'support@parkproptek.com';

export const SUPER_ADMIN_GROUP = 'Super-Admin';
export const ADMIN_GROUP = 'Admin';
export const INSPECTOR_GROUP = 'Inspector';
export const PLANNER_GROUP = 'Planner';
export const PROPERTY_OWNER_GROUP = 'Property-Owner';
export const ALT_USER_GROUP = 'Alt-User';

export const BUCKET_NAME = 'parkpro-images';
export const FILE_MIME_TYPES = ['application/pdf'];

export const AUDIT_TYPE = 2;
export const INSPECTION_TYPE = 1;
export const POWER_WASH_TYPE = 3;
export const INCLUSIVE_TYPE = 5;

// Convert between the mode used in the URL and the questionTypeId (also reportTypeId).
export const modeToQuestionTypeId = {
  "audit": AUDIT_TYPE,
  "inspect": INSPECTION_TYPE,
  "inclusive": INCLUSIVE_TYPE,
  "powerwash": POWER_WASH_TYPE
};
export const questionTypeIdToMode = {
  [AUDIT_TYPE]: 'audit',
  [INSPECTION_TYPE]: 'inspect',
  [INCLUSIVE_TYPE]: 'inclusive',
  [POWER_WASH_TYPE]: 'powerwash'
};

export const REPORT_TYPE = {
  AUDIT: 'Audit',
  INSPECTION: 'Inspection',
  INCLUSIVE: 'Inclusive',
  POWER_WASH: 'Power Washing'
}

export const SCHEDULE_TYPE = 'Schedule';

export const reportTypeToMode = {
  [REPORT_TYPE.AUDIT]: 'audit',
  [REPORT_TYPE.INSPECTION]: 'inspect',
  [REPORT_TYPE.INCLUSIVE]: 'inclusive',
  [REPORT_TYPE.POWER_WASH]: 'powerwash'
};

//Paths
export const QUESTIONS_PATH = '/planning/:id?';
export const QUESTION_ALL_PATH = '/planning';
export const PROPERTIES_PATH = '/property/:id?';
export const PROPERTIES_ALL_PATH = '/property';
export const DASHBOARD_PATH = '/dashboard';
export const INSPECTION_PATH = '/inspect';
export const INSPECTION_NEW_PATH = '/inspect/:id/:mode/:reportId';
export const INSPECTION_PROPERTY_PATH = '/inspect/:id';
export const INSPECTION_FINALIZED_PATH = '/inspect/finalized';
export const NON_COMPLIANCE_REPORT_PATH = '/inspect/non-compliance-report';
export const INVENTORY_PDF_PATH = '/inventory/:propertyId/report/:header?';
export const INVENTORY_PATH = '/inventory/:propertyId';
export const AUDIT_PDF_PATH =
  '/inspect/:propertyId/report/:reportId/:showImages?';
export const USERS_PATH = '/user/:id?';
export const USERS_ALL_PATH = '/user';
   export const CPSC_PATH = '/regulation/cpsc'
export const ASTM_PATH0 = '/regulation/astm/0'
export const ASTM_PATH1 = '/regulation/astm/1'
export const ASTM_PATH2 = '/regulation/astm/2'
export const ASTM_PATH3 = '/regulation/astm/3'
export const ASTM_PATH4 = '/regulation/astm/4'
export const ASTM_PATH5 = '/regulation/astm/5'
export const ASTM_PATH6 = '/regulation/astm/6'
export const ASTM_PATH7 = '/regulation/astm/7'
export const ASTM_PATH8 = '/regulation/astm/8'
export const REGULATION_PATH = '/regulation/:type?/:fileIndex?'
export const REGULATION_LIST_PATH = '/regulation'
export const CLIENTS_PATH = '/client/:id?';
export const CLIENTS_ALL_PATH = '/client';
export const MAP_PATH = '/map/:id?';
export const SHOW_ASSETS_PATH = '/show-assets/:id?';

//API URL
export const PDF_VIEW = `http://${HOST}/pdfview?reportId={reportId}`;
export const PDF_DOWNLOAD = `http://${HOST}/pdfdownload?reportId={reportId}`;

const startUrl = process.env.REACT_APP_ENDPOINT
  ? `https://${process.env.REACT_APP_ENDPOINT}`
  : '';

export const FACILITY_LOCATION_PATH = `${startUrl}/openstreets/address?city={city}&state={state}&zip={zipCode}`;
export const FACILITY_LOCATION_GEOCOD_PATH = `${startUrl}/geocode/address?addressLineOne={addressLineOne}&city={city}&state={state}&zip={zipCode}`;
export const WEATHER_API_PATH = `${startUrl}/weatherstack/address?addressLineOne={addressLineOne}&city={city}&state={state}&zip={zipCode}`;
export const WEATHER_API_LAT_LNG_PATH = `${startUrl}/weatherstack/latlon?lat={latitude}&lon={longitude}`;

export const ASTM_ZIP_PATH = !process.env.REACT_APP_ENDPOINT ? '/astm' : `https://${process.env.REACT_APP_ENDPOINT}/astm`;
export const ASTM_PDF_PATH = !process.env.REACT_APP_ENDPOINT ? '/astm' : `https://${process.env.REACT_APP_ENDPOINT}/astm`;
export const CPSC_PDF_PATH = !process.env.REACT_APP_ENDPOINT ? '/cpsc' : `https://${process.env.REACT_APP_ENDPOINT}/cpsc`;

export const ENTRY_SET_VALUE = {
  compliantNonCompliant: 1,
  yesNo: 2,
  text: 5
}

export const PRIORITIES = ["P1", "P2", "P3", "P4", "P5"];
