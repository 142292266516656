import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import DrawerContext from '../../components/DrawerContext';
import TextField from '../../components/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { parse, stringify } from 'query-string';
import Grid from './Grid';

const styles = (theme) =>
  createStyles({
    search: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.primary.contrastText} !important`,
      },
      '& ::placeholder': {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: theme.palette.type === 'dark' ? 0.6 : 0.4 /* Firefox */,
      },

      '& :-ms-input-placeholder': {
        /* Internet Explorer 10-11 */ opacity: theme.palette.type === 'dark' ? 0.6 : 0.4,
      },

      '& ::-ms-input-placeholder': {
        /* Microsoft Edge */ opacity: theme.palette.type === 'dark' ? 0.6 : 0.4,
      },

      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, theme.palette.type === 'dark' ? 0.2 : 0.5),
      '&:hover': {
        backgroundColor: fade(
          theme.palette.common.white,
          theme.palette.type === 'dark' ? 0.3 : 0.6,
        ),
      },
      marginLeft: 0,
      // width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      // width: theme.spacing(9),
      // height: '100%',
      // position: 'absolute',
      // pointerEvents: 'none',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    inputRoot: {
      color: 'inherit',
      // width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      // paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      // paddingLeft: theme.spacing(10),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    '::placeholder': {
      color: '#1796a4 !important',
    },
    textFieldStyle: {
      '& input': {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
        padding: 8,
      },
    },
    inputAdornmentStyle: {
      backgroundColor: '#1796a4',
      width: 46,
      height: 36,
      maxHeight: 'unset',
    },
    iconStyle: {
      color: 'white',
      margin: 'auto',
    },
  });

/**
 * The Search component that handles entering a search value.
 */
class Search extends PureComponent {
  static contextType = DrawerContext;
  static propTypes = {
    classes: PropTypes.any,
    value: PropTypes.string,
    onClose: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    const search = get(parse(props.location.search), 'searchText');
    this.state = {
      search,
      showClose: false,
    };

    document.addEventListener('keydown', this.handleKey, false);
  }

  componentDidUpdate(prevProps) {
    const { match = {} } = this.props;
    //Cancel search when moving to a new page.
    if (get(prevProps, 'match.path') !== match.path) {
      this.onCancelSearch();
    } else {
      if (prevProps.location.search !== this.props.location.search) {
        const search = parse(this.props.location.search, {
          parseNumbers: true,
          parseBooleans: true,
        });
        // if (search.searchText !== this.state.search) {
        this.setState({ search: search.searchText || '' });
        // }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey, false);
  }

  handleKey = (event) => {
    if (!event.defaultPrevented) {
      if (event.key === 'Escape' && event.target.name === 'search') {
        event.stopPropagation();
        event.preventDefault();
        this.onCancelSearch();
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.search) {
      this.setState({ search: nextProps.value });
    }
  }

  /**
   * When search data has changed.
   * @param target The change event.
   */
  handleChange = ({ target }) => {
    this.setState({ search: target.value }, this.handleSearchChange);
  };

  /**
   * When the user presses a key, if it is the escape key clear the search.
   * @param event The key down event.
   */
  onKeydown = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      this.onCancelSearch();
    }
  };

  /**
   * When the search is canceled, clear the search field.
   */
  onCancelSearch = () => {
    this.setState({ search: '' }, this.handleSearchChange);
    this.props.onClose && this.props.onClose();
  };

  /**
   * After debounce, perform the actual search of data for the search string the user entered.
   * @type {Function} the debounced function.
   */
  handleSearchChange = debounce(() => {
    const { onDrawerOpen } = this.context;
    const { history, location } = this.props;

    const existingSearch = parse(location.search, { parseBooleans: true, parseNumbers: true });
    const search = stringify({ ...existingSearch, searchText: this.state.search || undefined });
    history.push({ pathname: location.pathname, search, state: {...location.state} });
    onDrawerOpen();
  }, 750);

  render() {
    const { classes, placeholder } = this.props;
    const { search, showClose } = this.state;

    return (
      <Grid container className={classes.search} fullWidth={false}>
        <Grid item>
          <TextField
            name={'search'}
            value={search}
            className={classes.textFieldStyle}
            // inputProps={{onKeyDown: this.onKeyDown}}
            onChange={this.handleChange}
            placeholder={placeholder || 'Search…'}
            style={{ margin: 8 }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            InputProps={{
              'aria-label': 'Search',
              style: { paddingRight: 0 },
              className: classes.textFieldStyle,
              onFocus: () => this.setState({ showClose: true }),
              onBlur: () => this.setState({ showClose: false }),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornmentStyle}>
                  {showClose ? (
                    <CloseIcon className={classes.iconStyle} onMouseDown={this.onCancelSearch} />
                  ) : (
                    <SearchIcon className={classes.iconStyle} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(Search));
